
.c-icon-list {
  display: flex;
  flex-wrap: wrap;
  &-thumbnail {
    $pad: 5px;
      background-color: #eee;
      padding: $pad;
      border-radius: 5px;
      //width: 32px + 2*$pad;
      height: 32px + 2*$pad;
      display: flex;
      align-items: center;justify-content: center;
      &-outer {
        padding: 5px;
      }
      img {
        width: auto;
        height: 32px;
        image-rendering: pixelated;
      }
      border: 3px solid transparent;
    
      &.selected {
        border: 3px solid rebeccapurple;
      }
    }
   &:not(:first-child) {
    }
  }


.tag-option {
  background: #eaf1fb;
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  display: flex;
  align-self: baseline;
  max-width: 100%;
  overflow: hidden;
  user-select: none;
  padding: 5px;
  position: relative;
  z-index: 500;
}
