@import "./Image";

$background_colour: #333;

body {
  background-color: $background_colour;
  .footer {
    color: white;
    a {
      font-weight: bold;
      text-decoration: none;
      color: #aff;
    }
  }
}

.app-container {
  position: relative;
  .background-image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $background_colour;
    canvas {
      opacity: 0.2;
    }
  }
}

.full-height,
html {
  height: 100%;
}

.App {
  .sidebar {
    background-color: theme-color("primary");
    color: color("white");
  }
  .c-header {
    &-title {
      line-height: 1em;
    }
    img {
      width: 32px;
      height: 32px;
    }
    color: white;
    a {
      color: white;
    }
    &-background {
      background-color: theme-color("primary");
    }
  }
}

.c-draggable {
  &-list {
    padding: 5px;

    &-header {
      padding: 10px;
    }
    &-filter {
      display: flex;
      position: relative;
      justify-content: space-between;
      align-items: center;
      input,
      select {
        margin-left: 10px;
      }
      &-clear {
        position: absolute;
        right: 20px;
        top: 8px;
        color: white;
        background-color: #555;
        border-radius: 50%;
        width: 15px;
        height: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 1em;
        font-size: 10px;
        cursor: pointer;
      }
    }
    &-items {
      min-height: 40px;
      &__empty {
        border: 3px dashed lighten($color: grey, $amount: 40);
        padding: 20px;
      }
    }
  }
  &-image {
    width: 32px;
    height: auto;
    touch-action: none;
    image-rendering: pixelated;
  }
  margin: 5px;
  background: #fff;
  padding: 5px;
  border-radius: 5px;
  border: none;
  display: inline-block;
  position: relative;
}
.c-sortable {
  position: relative;
}
.c-removeable {
  $badgeSize: 16px;
  position: absolute;
  right: -$badgeSize/2;
  bottom: -$badgeSize/2;
  display: flex;
  justify-content: center;
  font-weight: bold;
  align-items: center;
  width: $badgeSize;
  height: $badgeSize;
  border-radius: $badgeSize/2;
  line-height: 1em;
  cursor: pointer;
  // unselected
  content: "";
  background-color: #080;
  background-image: url(img/plus.svg);
  background-size: 12px 12px;
  background-position: 50%;
  background-repeat: no-repeat;
  color: white;

  &__selected {
    background-color: #800;
    background-image: url(img/minus.svg);
    color: white;
  }
}

.c-clickable {
  cursor: pointer;
  $badgeSize: 16px;
  @extend .c-draggable;
  position: relative;

  &__selected {
    opacity: 0.25;
  }
}
.c-filedropzone {
  background-color: #eee;
  border-radius: 10px;
  padding: 20px;
  position: relative;
  cursor: pointer;
  &__current {
    position: absolute;
    top: 20px;
    left: 20px;
  }
  &__square {
    overflow: hidden;
    padding-bottom: 100%;
    .c-filedropzone-inner {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
    }
  }
  &-inner {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    //border: 4px dashed #aaa;
    img {
      margin-right: 5px;
      margin-bottom: 5px;
      max-width: 100%;
    }
  }
}

.drop-zone {
  background-color: red;
  height: 100%;
}
.c {
  &-link-list {
    list-style: none;
    padding: 0;
    display: flex;
    justify-self: flex-end;

    .btn.is-active {
      background-color: #aaf;
    }
  }
  &-clients {
    &__single {
      a {
        color: white;
      }
    }
  }
}

.offcanvas {
  &-header-custom {
    flex-wrap: wrap;
    .c-icon-list-thumbnail {
      margin-bottom: 5px;
    }
  }
  &-body {
    &-dimensions {
      display: block;
      &-block {
        display: inline-block;
        background-color: #ddf;
        border-radius: 5px;
        padding: 5px;
      }
    }
  }
}
